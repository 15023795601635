import React, { useState } from "react";
import "./contact.css";
import call from "../../assets/images/call.png";
import map from "../../assets/images/map.png";
import mail from "../../assets/images/mail.png";
import contactMap from "../../assets/images/contact-map.png";
import { Button, Input, TextArea } from "../custom-inputs/CustomInputs";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    var newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleSendEmail = () => {
    const { name, email, subject, message } = data;
    const mailtoLink = `mailto:hello@skillayouth.com?subject=${subject} from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;

    // Create a temporary anchor element and trigger a click event on it
    const tempLink = document.createElement('a');
    tempLink.href = mailtoLink;
    tempLink.click();
  }

  

  return (
    <div className="contact-div">
      <div className="contact-left">
        <h3>How can we help you?</h3>
        <div className="contact-left_details">
          <p>
            <img src={call} alt="call" />
            +234 (816) 575 8441
          </p>
          <p>
            <img src={mail} alt="mail" />
            hello@skillayouth.com
          </p>
          <p>
            <img src={map} alt="map" />
            TEHub building, opposite Redeem Mega parish church, beside Akure
            south local government office, ilesa road, Akure
          </p>
        </div>
        <div className="contact-left_map">
          <img src={contactMap} alt="map" />
        </div>
      </div>
      <div className="contact-right">
        <Input
          type={"text"}
          placeholder={"Full Name"}
          id={"name"}
          value={data?.name}
          onChange={(e) => handleChange(e)}
        />
        <Input
          type={"text"}
          placeholder={"Email Address"}
          id={"email"}
          value={data?.email}
          onChange={(e) => handleChange(e)}
        />
        <Input
          type={"text"}
          placeholder={"Subject"}
          id={"subject"}
          value={data?.subject}
          onChange={(e) => handleChange(e)}
        />
        <TextArea
          textLabel={"Message"}
          rows={5}
          cols={30}
          id={"message"}
          value={data?.message}
          onChange={(e) => handleChange(e)}
        />
        <div className="contact-right_btn">
          <Button btnClass={"btn contact-right_btn"} btnText={"Submit"} submitBtn={handleSendEmail} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
