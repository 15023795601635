import React from 'react'
import '../../components/contact/contact.css'
import Contact from '../../components/contact/Contact'

const ContactUs = () => {
  return (
    <div className='contactus'>
      <div className="contactus-head">
        <h2>Contact Us</h2>
      </div>
      <Contact />
    </div>
  )
}

export default ContactUs