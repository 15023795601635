import React, { useState } from "react";
import "./training.css";
// import users from "../../assets/images/users.png";
import { ArrowRight, Calendar, Location } from "iconsax-react";
import { Button } from "../custom-inputs/CustomInputs";
import { formatDate } from "../random-funcs/dateFormat";
import { formatTime } from "../random-funcs/timeFormat";
import TrainingForm from "./TrainingForm";

const ActiveTr = ({ active, data }) => {

  // const trImgs = [users, users, users, users, users];
  // console.log(data);

  const [displayForm, setDisplayForm] = useState(false);
  const [displayItem, setDisplayItem] = useState();

  const clickAppForm = (item) => {
    if(active){
      setDisplayForm(true);
      setDisplayItem(item);
    }
  }

  return (
    <>
    {data?.length === 0 && <h4>No Active/Past Training</h4>}
      {data?.length > 0 &&
        data?.map((item, idx) => {
          return (
            <div className="active-training" key={idx}>
              <div className="active-training_left">
                <img
                  className="training-img"
                  src={item?.image}
                  alt="active-img"
                />
                {item?.trainingPartners && <h3>Training partner</h3>}
                <div className="ti-div">
                  {item?.trainingPartners?.map((item,idx) => {
                    return (
                      <div className="t-partners" key={idx}>
                        <img src={item?.logoLink} alt="ti" />
                        <p>{item?.name}</p>

                      </div>
                    )
                  })}
                </div>
                {item?.collaboratingPartners && <h3>Collaborating partner</h3>}
                <div className="ti-div">
                  {item?.collaboratingPartners?.map((item,idx) => {
                    return (
                      <div className="t-partners" key={idx}>
                        <img src={item?.logoLink} alt="ti" />
                        <p>{item?.name}</p>

                      </div>
                    )
                  })}
                </div>
                {item?.fundingPartners && <h3>Funding partner</h3>}
                <div className="ti-div">
                  {item?.fundingPartners?.map((item,idx) => {
                    return (
                      <div className="t-partners" key={idx}>
                        <img src={item?.logoLink} alt="ti" />
                        <p>{item?.name}</p>
                      </div>
                    )
                  })}
                </div>
                <h4> </h4>
                {/* <div className="users-div">
                  {trImgs.map((item, idx) => {
                    return (
                      <div key={idx} className="users-div_img">
                        <img src={item} alt="users" />
                      </div>
                    );
                  })}
                  <p>100+</p>
                </div> */}
                <h5>{item?.enrolledUsers} people registered </h5>
                <h5>{item?.numberTrained} people trained </h5>
                <div className="app-stats">
                  <p>Status</p>
                  <h6 style={{ color: active ? "#07BEF7" : "#DB7E7E" }}>
                    {active ? "Application Open" : "Application Closed"}
                  </h6>
                </div>
              </div>
              <div className="active-training_right">
                <div className="at-right_top">
                  <h2>{item?.title}</h2>
                  <div className="at-date_div">
                    <div className="icon-at">
                      <Calendar />
                    </div>
                    {<p>
                      {formatDate(item?.startDate)} - {formatDate(item?.endDate)} <br />{" "}
                      <span>{formatTime(item?.startTime)} to {formatTime(item?.endTime)} {item?.trainingPeriod}</span>
                    </p>}
                  </div>
                  <div className="at-add_div">
                    <div className="icon-at">
                      <Location />
                    </div>
                    <p>
                      {item?.address} <br />{" "}
                      <span>
                        {item?.state}, {item?.country}
                      </span>
                    </p>
                  </div>
                  <div className="at-btn">
                    <Button
                      btnClass={
                        active ? "btn at-btn" : "btn-not-allowed at-btn"
                      }
                      btnText={active ? "Apply for free" : "Application closed"}
                      rightIcon={<ArrowRight />}
                      submitBtn={() => clickAppForm(item)}
                    />
                  </div>
                </div>
                <div className="at-right_bottom">
                  <div className="at-right_about">
                    <h4>About The Training</h4>
                    <p>This training will teach you the following:</p>
                    {item?.topics.map((item, idx) => {
                      return (
                        <ul key={idx}>
                          <li>{item}</li>
                        </ul>
                      );
                    })}
                  </div>
                  <div className="at-criteria">
                    <h4>Criteria:</h4>
                    {item?.criteria.map((item, idx) => {
                      return (
                        <ul key={idx}>
                          <li>{item}</li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <TrainingForm displayForm={displayForm} setDisplayForm={setDisplayForm} item={displayItem} />
    </>
  );
};

export default ActiveTr;
