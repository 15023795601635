import React, { useEffect, useState } from 'react'
import './team.css'
import twitter from "../../assets/images/twitter.png"
import instagram from "../../assets/images/instagram.png"
import linkedin from "../../assets/images/linkedin.png"
import { AxiosGet } from '../../api/api'

const Team = () => {

    const url = `team-members/`;

    const [teamMem, setTeamMem] = useState([])

    const getTeam = () => {
        AxiosGet(url).then((res) => {
            // console.log(res);
            setTeamMem(res.data);
        }).catch((err) => {
            // console.log(err.response);
        })
    }

    useEffect(() => {
        getTeam();
        // eslint-disable-next-line
    }, []);

  return (
    <div className='team'>
        <h2>Our Team</h2>
        <div className="team-group">
            {teamMem && teamMem.map((item, idx) => {
                return (
                    <div className="each-team" key={idx}>
                        <img src={item?.pictureLink} className='team-img' alt="team-member_pic" />
                        <h3>{item?.firstName + " " + item?.lastName}</h3>
                        <p>{item?.position}</p>
                        <div className="team-links">
                            <a href={item?.linkedinLink} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" /></a>
                            <a href={item?.instagramLink} target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a>
                            <a href={item?.twitterLink} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default Team