import React, { useState } from 'react'
import "./faq.css"
import faqImg from "../../assets/images/faq.png"
import { ArrowCircleDown, ArrowCircleUp } from 'iconsax-react'

const Faq = () => {

    const [showAns, setShowAns] = useState(false);
    const [showIdx, setShowIdx] = useState("");

    const faqArr = [
        {
            question: "What is SkillaYouth (SAY)?",
            answer: "SkillaYouth (SAY) is a non-profit initiative dedicated to empowering African youth through free technology, technical and vocational skills training.",
        },
        {
            question: "What kind of skills are offered through SAY?",
            answer: "SAY offers a variety of technology, technical and vocational skills training programs which includes tiling, drone piloting, and much more to come.",
        },
        {
            question: "Is there a cost to attend SAY programs?",
            answer: "All SAY programs are free for participants.",
        },
        {
            question: "Who is eligible to participate in SAY programs?",
            answer: "SAY programs are primarily designed for Ondo youth. Specific eligibility criteria may vary depending on the program and location.",
        },
        {
            question: "How long are the SAY programs?",
            answer: "The duration of SAY programs varies depending on the specific skill being taught. Some programs may be short-term, while others may be longer-term.",
        },
        {
            question: "What kind of support is provided to participants after completing a program?",
            answer: "SAY provides various forms of post-program support, which may include job placement assistance, mentorship, and access to entrepreneurial resources.",
        },
        {
            question: "How can businesses or organizations partner with SAY?",
            answer: "Businesses and organizations can partner with SAY in various ways, including providing financial support for training, donating equipment, offering internships or apprenticeships, or serving as mentors. It can be done under the “Partner with Us” section.",
        },
        {
            question: "How can I contact SAY?",
            answer: "You can contact SAY by using the contact information in the “Contact Us” page.",
        },
    ]

    
    const clickFaq = (idx) => {
        if(showIdx === idx){
            setShowAns(!showAns);
        }else{
            setShowAns(true);
        }
        setShowIdx(idx);
    }

  return (
    <div className='faq'>
        <div className="faq-head">
            <div className="faq-head_text">
                <h2>Frequently Asked Questions</h2>
                <p>Discover how SkillaYouth has empowered young Africans to build thriving careers and create positive change in their communities.</p>
            </div>
            <div className="faq-head_img">
                <img src={faqImg} alt="faq" />
            </div>
        </div>
        <div className="faq-questions">
            {faqArr.map(({question, answer}, idx) => {
                return (
                    <div className="each-question" key={idx}  onClick={() => clickFaq(idx)}>
                        <h5>{question} {showAns && showIdx === idx ? <ArrowCircleUp /> : <ArrowCircleDown />}</h5>
                        {showAns && showIdx === idx && <p>{answer}</p>}
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default Faq