import React from 'react';
import "./pag.css";
import { ArrowLeft, ArrowRight } from 'iconsax-react';

const Pag = ({next, prev, nextPage, currentPage, previousPage, numberOfPages }) => {

    const clickNext = () => {
        if(nextPage !== null){
            if(next){
                next()
            }
        }
    }
    const clickPrev = () => {
        if(previousPage !== null){
            if(prev){
                prev()
            }
        }
    }

  return (
    <div className='pag'>
        <div className={previousPage === null ? "disabled-pag pag-prev" : "pag-prev"} onClick={() => clickPrev()}>
            <ArrowLeft />
            {/* <p>Prev</p> */}
        </div>
        <div className={nextPage === null ? "disabled-pag pag-next" : "pag-next"} onClick={() => clickNext()}>
            {/* <p>Next</p> */}
            <ArrowRight />
        </div>
    </div>
  )
}

export default Pag