import React, { useEffect, useState } from 'react'
// import IconVideo from '../../components/icon-video/IconVideo'
import AboutComp from '../../components/about-comp/AboutComp'
import CoreValues from '../../components/core-values/CoreValues'
import Team from '../../components/team/Team'
import AlignUn from '../../components/align-un/AlignUn'
import { AxiosGet } from '../../api/api'

const AboutUs = () => {
  
  const url = `statistics/`;

  const [stat, setStat] = useState();

  const getStat = () => {
    // setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setStat(res.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getStat();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {/* <IconVideo about={true} /> */}
      <AboutComp about={true} statData={stat} />
      <CoreValues />
      <AlignUn />
      <Team />
    </div>
  )
}

export default AboutUs