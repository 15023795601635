import React, { useEffect, useState } from "react";
import "./gallery.css";
import { ArrowLeft, CloseSquare } from "iconsax-react";
import play from "../../assets/images/play-btn.png";
import ReactPlayer from "react-player";
import { AxiosGet } from "../../api/api";
import Loader from "../loader/Loader";
import Pag from "../pag/Pag";
import GallerySlide from "./GallerySlide";
import { Button } from "../custom-inputs/CustomInputs";

const GalleryComp = () => {
  const [galSub, setGalSub] = useState(["All Digitals"]);
  const [activeSub, setActiveSub] = useState("All Digitals");
  const [activeClick, setActiveClick] = useState("All");
  const [pageNum, setPageNum] = useState(1);
  const [galImages, setGalImages] = useState();
  const [galSingleImages, setGalSingleImages] = useState([]);
  const url = `gallery/?mediaType=${activeClick}&page=${pageNum}`;
  const [viewAllId, setViewAllId] = useState("");
  const [viewAllTitle, setViewAllTitle] = useState("");
  const singleUrl = `gallery/${viewAllId}/?mediaType=${activeClick}&page=${pageNum}&pageSize=20`;
  const [isLoading, setIsLoading] = useState(false);
  const [isViewAll, setIsViewAll] = useState(false);
  const [pagin, setPagin] = useState();
  const [displayOv, setDisplayOv] = useState(false);
  const [displayItem, setDisplayItem] = useState("");

  

  const showOv = (item) => {
    // console.log(item);
    setDisplayOv(!displayOv);
    setDisplayItem(item);
  };


  const getGal = () => {
    setIsLoading(true);
    AxiosGet(url).then((res) => {
      // console.log(res);
      setGalImages(res.data);
      setPagin(res.pagination)
      setIsLoading(false);
    }).catch((err) => {
      // console.log(err.response);
      setIsLoading(false);
    })
  }
  const getSingleGal = () => {
    setIsLoading(true);
    AxiosGet(singleUrl).then((res) => {
      // console.log(res.data);
      setGalSingleImages(res.data);
      setPagin(res.pagination);
      setIsLoading(false);
    }).catch((err) => {
      // console.log(err.response);
      setIsLoading(false);
    })
  }

  const clickView = (item) => {
    setIsViewAll(true);
    setIsLoading(true);
    setViewAllId(item?.training?.id);
    setViewAllTitle(item?.training?.title)
    setGalSub(["All Digitals", "Photos", "Videos"])
  }

  const clickNext = () => {
    setPageNum(pagin?.nextPage);
  }

  const clickPrev = () => {
    setPageNum(pagin?.previousPage);
  }

  useEffect(() => {
    getSingleGal();
    // eslint-disable-next-line
  }, [activeClick, pageNum, isViewAll])

  useEffect(() => {
    getGal();
    // eslint-disable-next-line
  }, [])
  // console.log(galImages);
  

  const changeActive = (item) => {
    setActiveSub(item);
    if (item === "Photos") {
      setActiveClick("Image")
    }
    if (item === "All Digitals") {
      setActiveClick("All")
    }
    if (item === "Videos") {
      setActiveClick("Video")
    }
  };

  const clickBack = () => {
    window.location.reload();
  }

  return (
    <div className="gallery">
      <div className="gallery-head">
        <div className="gallery-head_left"></div>
        <div className="gallery-head_right">
          <h2>Gallery</h2>
          <p>
            Explore our photo and video gallery to witness the impact of our
            training programs and the inspiring journeys of our fellows.
          </p>
        </div>
      </div>
      <div className="gallery-thumbnail">
        <div className="back-btn">
        {isViewAll && <Button btnClass={"btn-trans"} btnText={"Go back"} leftIcon={<ArrowLeft />} submitBtn={clickBack} />}
        </div>
        <div className="g-thumb_head">
          {galSub.map((item, idx) => {
            return (
              <p
                className={activeSub === item ? "active-thumb" : ""}
                onClick={() => changeActive(item)}
                key={idx}
              >
                {item}
              </p>
            );
          })}
        </div>
        {isLoading && <Loader />}
        {!isViewAll && !isLoading &&  galImages?.map((item, idx) => {
          return(
            <GallerySlide key={idx} trainingText={item?.training?.title} slideData={item?.gallery} submitBtn={() => clickView(item)} />
          )
        })}
          {isViewAll && !isLoading && <h2 style={{paddingTop: "20px"}}>{viewAllTitle}</h2>}
        {isViewAll && !isLoading && <div className="g-thumb_pic">
          {galSingleImages.map((item, idx) => {
            return (
              <div
                key={idx}
                className="each-img"
                onClick={() => showOv(item)}
              >
                <img
                  className={item.mediaType === "Video" ? "vid-img" : "img-class"}
                  src={item.mediaType !== "Video" ? item?.mediaLink : item?.thumbnailPic}
                  alt={"caption"}
                />
                {item.mediaType === "Video" && (
                  <div className="play-btn">
                    <img src={play} alt="play" />
                  </div>
                )}
              </div>
            );
          })}
          {displayOv && (
            <div onClick={showOv} className="overview">
              <div className="close-ov" onClick={showOv}>
                <CloseSquare />
              </div>
            </div>
          )}
          {displayOv && (
            <div className="enlarged-modal">
              {displayItem.mediaType !== "Video" && (
                <img src={displayItem?.mediaLink} alt={"caption"} />
              )}
            </div>
          )}
          {displayOv && (
            <div className="enlarged-modal">
              {displayItem.mediaType === "Video" && (
                <ReactPlayer
                  url={displayItem?.mediaLink}
                  playing={true}
                  // config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  onContextMenu={(e) => e.preventDefault()}
                  controls
                  width="100%"
                  height="500px"
                  // volume={1}
                />
              )}
            </div>
          )}
        </div>}
        {isViewAll && <Pag next={clickNext} prev={clickPrev} previousPage={pagin?.previousPage} nextPage={pagin?.nextPage} />}
      </div>
    </div>
  );
};

export default GalleryComp;
