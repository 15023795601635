import React, { useEffect, useState } from "react";
import "./navbar.css";
import textLogo from "../../assets/images/textLogo.png";
import textLogoWhite from "../../assets/images/textLogoWhite.png";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../custom-inputs/CustomInputs";
import { ArrowRight, CloseSquare, HambergerMenu } from "iconsax-react";
import twitterMobile from "../../assets/images/mobile-twitterX.png";
import facebookMobile from "../../assets/images/mobile-facebook.png";
import instaMobile from "../../assets/images/mobile-instagram.png";
import TrainingForm from "../trainings/TrainingForm";

const Navbar = () => {
  const pathname = useLocation().pathname;
  const [active, setActive] = useState("");
  const [menuClick, setMenuClick] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);

  const clickAppForm = () => {
    setMenuClick(!menuClick);
    setDisplayForm(true);
  };

  const openMenu = () => {
    setMenuClick(!menuClick);
  };

  useEffect(() => {
    setActive(pathname);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const navLinks = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About Us",
      link: "/aboutus",
    },
    {
      text: "Gallery",
      link: "/gallery",
    },
    {
      text: "Training",
      link: "/training",
    },
    {
      text: "Contact Us",
      link: "/contactus",
    },
  ];

  return (
    <div className="nav-bar">
      <div className="desktop-nav">
        <div className="nav-left">
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <img src={textLogo} alt="logo" />
          </Link>
        </div>
        <div className="nav-right">
          <div className="nav-links">
            {navLinks.map(({ text, link }, idx) => {
              return (
                <ul key={idx}>
                  <Link to={link} style={{ textDecoration: "none" }}>
                    <li className={active === link ? "active" : ""}>
                      {text}
                      {active === link && <span></span>}
                    </li>
                  </Link>
                </ul>
              );
            })}
          </div>
          <div className="nav-btn">
            <Button
              btnText={"Join Community"}
              rightIcon={<ArrowRight />}
              btnClass={"btn-trans"}
              submitBtn={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSdLRZ2ef3b85yTZFR_1a-Puju-K2e2AGPtlgDpfbZ0FAr3tFA/viewform?usp=sf_link, '_blank'"
                )
              }
            />
          </div>
          <div className="mobile-nav">
            <div className="menu">
              <HambergerMenu onClick={openMenu} />
            </div>
            <div
              className="mobile-menu_close"
              style={{ display: !menuClick && "none" }}
            >
              <div className="close-menu">
                <img src={textLogoWhite} alt="logo" />
                <CloseSquare onClick={openMenu} />
              </div>
              <div className="close-menu_menu">
                {navLinks.map(({ text, link }, idx) => {
                  return (
                    <ul key={idx}>
                      <Link to={link} style={{ textDecoration: "none" }}>
                        <li
                          onClick={openMenu}
                          className={active === link ? "mobile-active" : ""}
                        >
                          {text}
                          {active === link && <span></span>}
                        </li>
                      </Link>
                    </ul>
                  );
                })}
              </div>
              <div className="close-menu_btn">
                <Button
                  btnText={"Apply for free"}
                  rightIcon={<ArrowRight />}
                  btnClass={"btn-white"}
                  submitBtn={clickAppForm}
                />
              </div>
              <div className="close-menu_links">
                <a
                  href="https://www.instagram.com/skillayouth/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instaMobile} alt="social-links" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61565716136386&mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebookMobile} alt="social-links" />
                </a>
                <a
                  href="https://x.com/skillayouthng"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterMobile} alt="social-links" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TrainingForm displayForm={displayForm} setDisplayForm={setDisplayForm} />
    </div>
  );
};

export default Navbar;
