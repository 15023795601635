import React, { useState } from "react";
import "./join-say.css";
import { Button } from "../custom-inputs/CustomInputs";
import { ArrowRight } from "iconsax-react";

const JoinSayComp = ({
  headerText,
  headerTitle,
  btnClass,
  btnText,
  pic,
  bgColor,
  headColor,
  moreArray,
  submitBtn,
}) => {
  const [readMore, setReadMore] = useState(false);

  const clickRead = () => {
    setReadMore(!readMore);
  };

  return (
    <div
      style={{ background: bgColor, color: headColor }}
      className="joinsay-comp"
    >
      <img src={pic} alt="apply" />
      <div className="joinsay-comp_head">
        <h3>{headerTitle}</h3>
        <p>{headerText}</p>
        {!readMore && <h5 onClick={clickRead}>Read More</h5>}
      </div>
      {readMore &&  (
        <div className="more-array">
          {moreArray.map((item, idx) => {
            return (
              <div className="each-more" key={idx}>
                <h4>{item.head}</h4>
                <ul>
                    {item.body.map((item, idx) => {
                        return(
                            <div className="each-body" key={idx}>
                                <li>{item}</li>
                            </div>
                        )
                    })}
                </ul>
              </div>
            );
          })}
        <h5 onClick={clickRead}>Read Less</h5>
        </div>
      )}
      <Button
        btnClass={btnClass}
        btnText={btnText}
        submitBtn={submitBtn}
        rightIcon={<ArrowRight />}
      />
    </div>
  );
};

export default JoinSayComp;
