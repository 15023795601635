import React from "react";
import "./align.css";
import un from "../../assets/images/un.png";
import tick from "../../assets/images/tick-circle.png"

const AlignUn = () => {

    const innerWidth = window.innerWidth;

    const unArr = [
        {
            goal: "Goal 4:",
            title: "Quality Education:",
            text: "We provide inclusive and equitable quality education and promote lifelong learning opportunities for all."
        },
        {
            goal: "Goal 8:",
            title: "Decent Work and Economic Growth:",
            text: "We promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all."
        },
        {
            goal: "Goal 9:",
            title: "Industry, Innovation, and Infrastructure:",
            text: "We build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation."
        },
        {
            goal: "Goal 10:",
            title: "Reduced Inequalities:",
            text: "We reduce inequality within and among countries."
        },
        {
            goal: "Goal 17:",
            title: "Partnerships for the Goals:",
            text: "We strengthen the means of implementation and revitalize the global partnership for sustainable development."
        },
    ]

  return (
    <div className="align-un">
    <div className="left-top"></div>
    {/* <div className="left-top-rad"></div> */}
      <div className="align-left">
        <div className="left-bottom">
          <img src={un} alt="un" />
        </div>
      </div>
      <div className="align-right">
        <h3>Alignment with UN <br /> <span>Sustainable Development {innerWidth > 900 && <br />} Goals</span> :</h3>
        <div className="align-right_goals">
            {unArr.map(({goal, title, text}, idx) => {
                return(
                    <div className="each-goal" key={idx}>
                        <img src={tick} alt="tick" />
                        <p>{goal} <span>{title}</span> <span>{text}</span></p>
                    </div>
                )
            })}
        </div>
      </div>
    </div>
  );
};

export default AlignUn;
