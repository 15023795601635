import React from 'react'
import "./about-comp.css"
import say from "../../assets/images/say.png"

const AboutComp = ({about, statData}) => {

  const innerWidth = window.innerWidth
  
  const aboutStyle = {
    background: "#DEFDFF",
    borderRadius: "38px",
    padding: innerWidth > 900 ? "35px" : "20px",
    borderTopRightRadius: "0",
    width: innerWidth < 900 && "90%",
  }

  return (
    <div className='about-comp' style={about && aboutStyle}>
        <div className="about-comp_left">
            <p><span></span>About Us</p>
            <img src={say} alt="skill-a-youth" />
        </div>
        <div className="about-comp_right">
            <h2>Non-profit initiative empowering <span>African youth</span>  through free technology, technical and vocational skills.</h2>
            <p>SkillaYouth (SAY) is a non-profit initiative empowering African youth through free technology, technical and vocational skills training. We partner with artisans, businesses, and experts to provide comprehensive programs that equip young people with the skills they need to thrive in the workforce.</p>
            <div className="floating-right">
                <p>{statData?.numberOfPartners}+ <br /> <span>Patners, Sponsors 
                and Collaborators</span></p>
            </div>
        </div>
    </div>
  )
}

export default AboutComp