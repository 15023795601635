import { Routes, Route } from "react-router-dom"
import "./App.css"
import Home from "./pages/website/Home"
import Nopage from "./pages/Nopage"
import AboutUs from "./pages/website/AboutUs"
import Gallery from "./pages/website/Gallery"
import Training from "./pages/website/Training"
import ContactUs from "./pages/website/ContactUs"
import WebLayout from "./components/layouts/WebLayout"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<WebLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/training" element={<Training />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="*" element={<Nopage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
