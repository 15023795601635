import React, { useState } from 'react';
import "./custom-inputs.css";
import { ArrowDown2, ArrowUp2, CloseCircle, CloseSquare } from "iconsax-react";
import { truncate } from '../random-funcs/utils';

const MultiSelect = ({optionsArray,selected, setSelected, selectPlaceholder, formLabel, errorMessage, dropHeight, setErrorMessage}) => {
    const [display, setDisplay] = useState(false);
    const [selectOptions, setSelectOptions] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(optionsArray);
    const showOptions = () => {
      setDisplay(!display);
      setFilteredOptions(optionsArray);
      if (setErrorMessage){
          setErrorMessage("")
      }
    };
    const optionsClick = (item) => {
      if (!selected.find((idx) => idx.id === item.id)) {
        setSelected([...selected, item]);
      }
      // console.log(item);
    };
    const cancelClick = (item) => {
      setSelected(selected.filter((idx) => idx.id !== item.id));
    };
    const selectAll = () => {
      setSelectOptions(!selectOptions);
      if (selected){
          if (!selectOptions) {
            setSelected(filteredOptions);
          } else {
            setSelected([]);
          }
      }
    };
    const handleSearch = (e) => {
      // console.log(e.target.value);
      const newArray = optionsArray.filter((option) =>
        option.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredOptions(newArray);
    };
  
    return (
      <div className="multiple-select multi-select">
          <h3>{formLabel}</h3>
        <div onClick={showOptions} className="options-head">
          <h3>
            {selected.length === 0
              ? selectPlaceholder
              : selected && selected.map((item) => {
                  return truncate(item.label)  + ", ";
                })}
          </h3>{" "}
          <span>
            {selected && selected.length !== 0 ? (
              <CloseSquare onClick={() => setSelected([])} />
            ) : (
              ""
            )}{" "}
            {!display ? <ArrowDown2 size={16} /> : <ArrowUp2 size={16} />}
          </span>
        </div>
        <div className={!display ? "display-none" : "multiple-select_options"} style={{height: dropHeight}}>
          <input type="text" placeholder="Search Options..." onChange={handleSearch} />
          <h4 onClick={selectAll} className={selectOptions ? "selected-css" : ""}>
            Select All
          </h4>
          {filteredOptions && filteredOptions.length === 0 ? <p>Nothing to show here</p> :
            filteredOptions.map((item, idx) => {
              return (
                <p
                  onClick={() => optionsClick(item)}
                  key={idx}
                  id={item.id}
                  className={
                    selected && selected?.find((idx) => idx.id === item.id)
                      ? "selected-css"
                      : ""
                  }
                >
                  {item.label}{" "}
                  {selected && selected?.find((idx) => idx.id === item.id) ? (
                    <span>
                      <CloseCircle onClick={() => cancelClick(item)} />
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              );
            })}
        </div>
        {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}
      </div>
    );
}

export default MultiSelect