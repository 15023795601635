import React, { useEffect, useState } from "react";
import "./training.css";
import ActiveTr from "./ActiveTr";
import { AxiosGet } from "../../api/api";
import Loader from "../loader/Loader";

const TrainingComp = () => {
  const trList = [
    "All",
    "Vocational skills",
    "Technical skills",
    "Digital skills",
    "Creative skills",
  ];

  const [trainingActive, setTrainingActive] = useState("Active Trainings");
  const [trListActive, setTrListActive] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [trData, setTrData] = useState([]);

  const url = `training/?trainingCategory=${trListActive}&timeStatus=${
    trainingActive.trim().split(" ")[0]
  }`;

  const getTraining = () => {
    setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setTrData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTraining();
    // eslint-disable-next-line
  }, [trListActive, trainingActive]);

  const changeList = (item) => {
    setTrListActive(item);
  };
  const changeTr = (e) => {
    setTrainingActive(e.target.textContent);
  };

  return (
    <div className="training">
      <div className="training-head">
        <div className="training-head_left">
          {trList.map((item, idx) => {
            return (
              <div className="each-list" key={idx}>
                <p
                  onClick={() => changeList(item)}
                  className={trListActive === item ? "active-list" : ""}
                >
                  {item === "All" ? "All categories" : item}
                </p>
              </div>
            );
          })}
        </div>
        <div className="training-head_right">
          <h3>Training Programs Schedules</h3>
          <h4>
            Gain in-demand skills and knowledge through our comprehensive
            vocational and technical training programs.
          </h4>
          <div className="tr-active_menu">
            <p
              onClick={(e) => changeTr(e)}
              className={
                trainingActive === "Active Trainings" ? "active-tr" : ""
              }
            >
              Active Trainings
            </p>
            <p
              onClick={(e) => changeTr(e)}
              className={trainingActive === "Past Trainings" ? "active-tr" : ""}
            >
              Past Trainings
            </p>
            <p
              onClick={(e) => changeTr(e)}
              className={trainingActive === "Upcoming Trainings" ? "active-tr" : ""}
            >
              Upcoming Trainings
            </p>
          </div>
        </div>
      </div>
      <div className="training-body">
        <h3>{trainingActive}</h3>
        <div className="training-body_cont">
            {isLoading && <Loader />}
          {!isLoading && <ActiveTr
            active={trainingActive === "Active Trainings" || trainingActive === "Upcoming Trainings"  ? true : false}
            data={trData}
          />}
        </div>
      </div>
    </div>
  );
};

export default TrainingComp;
