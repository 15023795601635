import React from 'react'
import TrainingComp from '../../components/trainings/TrainingComp'

const Training = () => {
  return (
    <div>
      <TrainingComp />
    </div>
  )
}

export default Training