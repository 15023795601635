import React from 'react';
import "./loader.css"

const Loader = ({loadHeight, loadWidth}) => {
  return (
    <div className="loader-cont">
        <div className="lds-ripple" style={{width: loadWidth, height: loadHeight}}><div></div><div></div></div>

    </div>
  )
}

export default Loader