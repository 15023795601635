import React from "react";
import "./core-values.css";

const CoreValues = () => {
  const coreArr = [
    {
      number: "01",
      title: "Accessibility:",
      text: "We are committed to providing free access to vocational and technical skills training for all youth, regardless of their background or circumstances.",
    },
    {
      number: "02",
      title: "Collaboration: ",
      text: "We believe in the power of partnership. We work closely with artisans, technical businesses, experts, and government agencies to ensure our programs are relevant, effective, and accessible.",
    },
    {
      number: "03",
      title: "Empowerment: ",
      text: "We strive to empower youth not only with technical skills but also with the confidence, resilience, and entrepreneurial mindset they need to succeed.",
    },
    {
      number: "04",
      title: "Community:",
      text: "We foster a vibrant and supportive community where fellows can connect, learn from each other, and build lasting relationships.",
    },
  ];

  return (
    <div className="core-values">
      <div className="core-values_left">
        <h3>Core Values</h3>
        <div className="core-values_cont">
          {coreArr.map(({ number, title, text }, idx) => {
            return (
              <div className="each-core_value" key={idx}>
                <h4>{number}</h4>
                <div className="each-core_body">
                  <h5>{title}</h5>
                  <p>{text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="core-values_right">
        <div className="core-mission">
          <h3>Mission Statement</h3>
          <p>At SkillaYouth, our mission is to empower African youth by providing free access to high-quality vocational and technical skills training. We believe that every young person deserves the opportunity to acquire the skills they need to build a successful career, start a business, and contribute to their communities.</p>
        </div>
        <div className="core-mission">
          <h3>Vision Statement</h3>
          <p>Our vision is to create a skilled and empowered youth workforce that drives innovation, economic growth, and social development across Africa. We envision a future where all young people have the knowledge and resources to reach their full potential and create a brighter future for themselves and their families.</p>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
