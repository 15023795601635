import React from "react";
import "./custom-inputs.css";
import loader from "../../assets/images/loading-gif.gif";
import { TickCircle } from "iconsax-react";

export const Input = ({ type, placeholder, onChange, label, id, small, error, value }) => {
  const changeInput = (e) => {
    e.preventDefault();
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <div className={small ? "small-input" : "input"}>
      <label htmlFor={label}>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        id={id}
        onChange={(e) => changeInput(e)}
      />
      {error && <p className="err-p">{error}</p>}
    </div>
  );
};

export const TextArea = ({
  textLabel,
  textName,
  onChange,
  label,
  rows,
  cols,
  id, 
  value
}) => {
  const changeInput = (e) => {
    e.preventDefault();
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <div className="textarea">
      <label htmlFor={label}>{label}</label>
      <textarea
        name={textName}
        id={id}
        value={value}
        rows={rows}
        cols={cols}
        onChange={(e) => changeInput(e)}
      ></textarea>
    </div>
  );
};

export const Button = ({
  btnClass,
  btnText,
  rightIcon,
  leftIcon,
  submitBtn,
  btnLink,
  loading,
  success
}) => {
  const clickBtn = (e) => {
    e.preventDefault();
    if (submitBtn) {
      submitBtn();
    }
  };
  return (
    <a href={btnLink} target="_blank" rel="noreferrer">
      <button className={btnClass} onClick={(e) => clickBtn(e)}>
        {!loading && <>
        {leftIcon}
        {btnText}
        {rightIcon}
        </>}
        {loading && <img src={loader} alt="loading..." />}
      </button>
      {success && <p className="sus-p"><TickCircle /> {success}</p>}
    </a>
  );
};


export const SelectInput = ({ onChange, label, id, optionArray, small, error }) => {
  const changeInput = (e) => {
    e.preventDefault();
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={small ? "small-input" : "input"}>
      <label htmlFor={label}>{label}</label>
      <select id={id} onChange={(e) => changeInput(e)}>
        {optionArray && optionArray?.map((item, idx) => {
          return(
            <option key={idx} id={item?.id} value={item?.id}>{item?.label}</option>
          )
        })}
      </select>
      {error && <p className="err-p">{error}</p>}
    </div>
  );
};
