import axios from "axios";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export async function AxiosGet(url) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    try {
      const res = await axios.get(`${baseUrl}${url}`, config);
      return res.data;
    } catch (err) {
      throw err;
    }
  }

export async function AxiosPost(url, dataObject) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    try {
      const res = await axios.post(`${baseUrl}${url}`, dataObject, config);
      return res.data;
    } catch (err) {
      throw err;
    }
  }