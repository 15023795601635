import React, { useEffect, useState } from "react";
import "./testimonials.css";
import testi from "../../assets/images/testimonials.png";

import { register } from "swiper/element/bundle";
import { AxiosGet } from "../../api/api";
// register Swiper custom elements
register();

const Testimonials = () => {
  const url = `testimonies/`
  const [tesArr, setTesArr] = useState([]);

  const innerWidth = window.innerWidth;

  const getTes = () => {
    AxiosGet(url).then((res) => {
      // console.log(res);
      setTesArr(res.data)
    }).catch((err) => {
      // console.log(err.response);
    })
  }

  useEffect(() => {
    getTes();
    // eslint-disable-next-line
  }, [])



  return (
    <div className="testimonials">
      <div className="testi-head">
        <div className="testi-head_left">
          <h2>
            Real Stories, Real Results: <br /> SkillaYouth Testimonials
          </h2>
          <p>
            Discover how SkillaYouth has empowered young Africans to build
            thriving careers and create positive change in their communities.
          </p>
        </div>
        <div className="testi-head_right">
          <img src={testi} alt="testimonial" />
        </div>
      </div>
      <div className="testi-body">
        <div className="slider-img-tes">
          <swiper-container
            // ref={swiperElRef}
            slides-per-view={innerWidth > "900" ? "3" : "1.2"}
            space-between="20"
            // navigation="true"
            pagination="true"
            speed="100"
            // loop="true"
            autoplay-delay="5000"
            css-mode="true"
          >
            {tesArr.map((item, idx) => {
              return (
                <swiper-slide className="slide-cont" key={idx}>
                  <div className="each-testi">
                    <div className="each-head">
                      <div className="each-head_text">
                        <h4>{item?.name}</h4>
                        <h5>{item?.city + ", " + item?.state}</h5>
                      </div>
                    </div>
                    <div className="each-body">
                      <p>" {item?.content} "</p>
                    </div>
                  </div>
                </swiper-slide>
              );
            })}
          </swiper-container>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
