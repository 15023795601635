import React, { useState } from "react";
import "./join-say.css";
import JoinSayComp from "./JoinSayComp";
import justSay from "../../assets/images/justsay1.png";
import justSayn from "../../assets/images/justsay2.png";
import TrainingForm from "../trainings/TrainingForm";

const JoinSay = () => {
    const [displayForm, setDisplayForm] = useState(false);
  
    const clickAppForm = () => {
        setDisplayForm(true);
    }
  

  const trainArr = [
    {
      head: "Eligibility Criteria:",
      body: [
        "Age: 18-35 years old (or specify your desired age range)",
        "Location: (Specify the countries or regions you serve)",
        "Education: (Specify minimum educational requirements, if any)",
        "Motivation: A strong desire to learn and grow in your chosen field",
      ],
    },
    {
      head: "Required Documents:",
      body: [
        "Completed application form",
        "Resume/CV",
        "Copy of ID or passport",
      ],
    },
    {
      head: "Submission Guidelines:",
      body: [
        "Review the eligibility criteria and program details carefully.",
        "Complete the online application form.",
        "Upload all required documents.",
        "Submit your application before the deadline.",
      ],
    },
  ];

  const partArr = [
    {
      head: "Partnership Benefits:",
      body: [
        "Access to a pool of motivated and talented young people",
        "Opportunity to give back to your community",
        "Enhanced visibility for your business or brand",
        "Networking and collaboration opportunities",
        "(Additional benefits you offer)",
      ],
    },
    {
      head: "Requirements:",
      body: [
        "Proven expertise in your field",
        "Commitment to providing quality training",
      ],
    },
    {
      head: "Onboarding Process:",
      body: [
        "Submit an expression of interest form.",
        "Our team will review your application and contact you for further discussion.",
        "We'll work together to develop a training program that meets our standards and aligns with your expertise.",
        "We'll provide support and resources to help you deliver a successful training program.",
      ],
    },
  ];

  return (
    <div className="join-say">
      <div className="join-say_head">
        <h2>Join the SkillaYouth Movement</h2>
      </div>
      <div className="join-say_body">
        <JoinSayComp
          pic={justSay}
          btnText={"Apply as Fellow"}
          btnClass={"btn"}
          bgColor={"white"}
          headerTitle={"Apply for Free Training"}
          headerText={
            "Are you a young African passionate about learning and ready to gain in-demand skills? SkillaYouth offers a variety of free vocational and technical training programs to help you unlock your potential and achieve your career goals."
          }
          headColor={"#0D2D53"}
          moreArray={trainArr}
          id={"fellow"}
          submitBtn={clickAppForm}
        />
        <JoinSayComp
          pic={justSayn}
          btnText={"Apply as Trainer"}
          btnClass={"btn-white"}
          bgColor={"#0D2D53"}
          headerTitle={"Partner with SkillaYouth"}
          headerText={
            "Are you an artisan, technical business, or expert passionate about empowering African youth? Partner with SkillaYouth to share your expertise and provide valuable training opportunities."
          }
          headColor={"white"}
          moreArray={partArr}
          id={"trainer"}
          submitBtn={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSdg6E2xEMA21d6Qnazl2FykdudFQ9jXR7s0nikSoFbaUPG_qQ/viewform?usp=sf_link, '_blank'"
            )
          }
        />
      </div>
      <TrainingForm displayForm={displayForm} setDisplayForm={setDisplayForm} />
    </div>
  );
};

export default JoinSay;
