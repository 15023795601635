import { useRef, useEffect, useState } from "react";
import "./training-parts.css";
import { Button } from "../custom-inputs/CustomInputs";
import { ArrowRight } from "iconsax-react";
import "swiper/element/css/pagination";
import { register } from "swiper/element/bundle";
// import function to register Swiper custom elements
import { Link } from "react-router-dom";
import { AxiosGet } from "../../api/api";
import Loader from "../loader/Loader";
// register Swiper custom elements
register();

const TrainingPart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [trData, setTrData] = useState([]);
  const [trDataPart, setTrDataPart] = useState();

  const url = `training/?trainingCategory=${"All"}&timeStatus=${"Upcoming"}`;
  const urlPart = `training-partners`;

  const getTraining = () => {
    setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setTrData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };
  const getTrainingPart = () => {
    setIsLoading(true);
    AxiosGet(urlPart)
      .then((res) => {
        // console.log(res);
        setTrDataPart(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTraining();
    getTrainingPart();
    // eslint-disable-next-line
  }, []);

  const innerWidth = window.innerWidth;

  const swiperElRef = useRef(null);

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener("swiperprogress", (e) => {
      // const [swiper, progress] = e.detail;
      // console.log(progress);
    });

    swiperElRef.current.addEventListener("swiperslidechange", (e) => {
      // console.log("slide changed");
    });
  }, []);

  return (
    <div className="training-parts">
      <div className="t-header">
        <h2>Our Training Partners</h2>
        <p>
          {" "}
          We collaborate with a network of dedicated partners to provide
          transformative opportunities for African youth.
        </p>
      </div>
      <div className="training-partners">
        {trDataPart?.trainingPartner && (
          <div className="each-partner">
            <h2>Training Partner</h2>
            <div className="t-logo">
              {trDataPart?.trainingPartner.map((item, idx) => {
                return <img src={item.logoLink} key={idx} alt="logo" />;
              })}
            </div>
          </div>
        )}
        {trDataPart?.fundingPartner && (
          <div className="each-partner">
            <h2>Funding Partner</h2>
            <div className="t-logo">
              {trDataPart?.fundingPartner.map((item, idx) => {
                return <img src={item.logoLink} key={idx} alt="logo" />;
              })}
            </div>
          </div>
        )}
        {trDataPart?.collaboratingPartner && (
          <div className="each-partner">
            <h2>Collaborating Partner</h2>
            <div className="t-logo">
              {trDataPart?.collaboratingPartner.map((item, idx) => {
                return <img src={item.logoLink} key={idx} alt="logo" />;
              })}
            </div>
          </div>
        )}
      </div>
      {/* <div className="t-logo">
        {logos.map((item, idx) => {
          return <img src={item} key={idx} alt="logo" />;
        })}
      </div> */}
      <div className="t-partner">
        <h3>Patner with Us</h3>
        <p>
          We are always looking for new partners who are committed to empowering
          African youth. If your organization is interested in collaborating
          with SkillaYouth, we invite you to create a brighter future for young
          people across Africa.
        </p>
        <Button
          btnClass={"btn-blue"}
          btnText={"Patner with Us"}
          rightIcon={<ArrowRight />}
          submitBtn={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSdg6E2xEMA21d6Qnazl2FykdudFQ9jXR7s0nikSoFbaUPG_qQ/viewform?usp=sf_link, '_blank'"
            )
          }
        />
      </div>
      <div className="upcoming-t">
        <div className="upcoming-t_head">
          <h2>Upcoming Trainings</h2>
          <h6> </h6>
        </div>
        <Link style={{ textDecoration: "none" }} to={"/training"}>
          {" "}
          <p>
            View More <ArrowRight />
          </p>
        </Link>
      </div>
      <div className="slider-img">
        <swiper-container
          ref={swiperElRef}
          slides-per-view={innerWidth > "900" ? "1.6" : "1.17"}
          space-between="25"
          // navigation="true"
          pagination="true"
          speed="100"
          // loop="true"
          autoplay-delay="5000"
          css-mode="true"
        >
          {isLoading && <Loader />}
          {trData?.length === 0 && <h4>No Upcoming Training</h4>}
          {trData?.length > 0 &&
            trData?.map((item, idx) => {
              return (
                <swiper-slide key={idx} className="slide-cont" lazy="true">
                  <img src={item?.image} alt="lazy" />
                </swiper-slide>
              );
            })}
        </swiper-container>
      </div>
    </div>
  );
};

export default TrainingPart;
