import React, { useEffect, useState } from "react"
import Hero from "../../components/hero/Hero"
// import IconVideo from "../../components/icon-video/IconVideo";
import AboutComp from "../../components/about-comp/AboutComp";
import TrainingPart from "../../components/TrainingPartners/TrainingPart";
import Testimonials from "../../components/testimonials/Testimonials";
import JoinSay from "../../components/join-say/JoinSay";
import Faq from "../../components/faq/Faq";
import { AxiosGet } from "../../api/api";

const Home = () => {
  
  const url = `statistics/`;

  const [stat, setStat] = useState();

  const getStat = () => {
    // setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setStat(res.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getStat();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Hero statData={stat} />
      {/* <IconVideo /> */}
      <AboutComp statData={stat} />
      <TrainingPart />
      <Testimonials />
      <JoinSay />
      <Faq />
    </div>
  );
};

export default Home;
