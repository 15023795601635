import React from "react"
import "./footer.css"
import textLogo from "../../assets/images/textLogoWhite.png"
import twitter from "../../assets/images/twitter.png"
import facebook from "../../assets/images/facebook.png"
import linkedin from "../../assets/images/linkedin.png"
import telegram from "../../assets/images/telegram.png"
import instagram from "../../assets/images/instagram.png"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-left">
        <img src={textLogo} alt="logo" />
        <h1>
          Empowering Africans Through free Technology, Technical and Vocational Skills
          Training
        </h1>
        <p>&copy; All rights reserved 2024</p>
      </div>
      <div className="footer-right">
        <h4>Our Social Networks</h4>
        <h5>You can contact us on the following social media handle links</h5>
        <div className="footer-logos">
        <a href="https://www.facebook.com/profile.php?id=61565716136386&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook-icon" /></a>
            <Link to={""}><img src={telegram} alt="icon" /></Link>
            <Link to={""}><img src={linkedin} alt="icon" /></Link>
            <a href="https://www.instagram.com/skillayouth/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram-icon" /></a>
            <a href="https://x.com/skillayouthng" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter-icon" /></a>
        </div>
      </div>
      <p className="mobile-p">2024 Logo. All rights reserved</p>
    </div>
  );
};

export default Footer;
