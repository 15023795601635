import React, { useState } from "react";
import "./hero.css";
import heroImg from "../../assets/images/hero-img.png";
import tehub from "../../assets/images/tehub.png";
import linkedin from "../../assets/images/linkedin-blue.png";
import instagram from "../../assets/images/instagram-blue.png";
import twitter from "../../assets/images/twitter-blue.png";
import { Button } from "../custom-inputs/CustomInputs";
import { ArrowRight } from "iconsax-react";
import TrainingForm from "../trainings/TrainingForm";

const Hero = ({statData}) => {
  const [displayForm, setDisplayForm] = useState(false);

  const clickAppForm = () => {
      setDisplayForm(true);
  }

  return (
    <div className="hero">
      <div className="hero-left">
        <div className="hero-text">
          <h1>
            Empowering Africans Youths Through <span>Free</span> Technology, Technical and Vocational <span>Skills Training</span>
          </h1>
          <span className="span-line"></span>
        </div>
        <p className="youth-p">
          {statData?.numberTrained}+ <br />
          <span>Youths Successfully Trained</span>
        </p>
        <div className="power-tehub">
          <p>Powered by</p>
          <img src={tehub} alt="tehub-logo" style={{width: "50px", height: "50px"}} />
        </div>
        <div className="button-icon">
          <Button
            btnClass={"btn"}
            btnText={"Apply as Fellow"}
            rightIcon={<ArrowRight />}
            submitBtn={clickAppForm}
          />
          <div className="hero-icons">
            <a href="http://" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin-icon" /></a>
            <a href="https://www.instagram.com/skillayouth/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram-icon" /></a>
            <a href="https://x.com/skillayouthng" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter-icon" /></a>
          </div>
        </div>
      </div>
      <div className="hero-right">
        <img src={heroImg} alt="hero" />
      </div>
      <TrainingForm displayForm={displayForm} setDisplayForm={setDisplayForm} />
    </div>
  );
};

export default Hero;
