import React from 'react'
import GalleryComp from '../../components/gallery-comp/GalleryComp'

const Gallery = () => {
  return (
    <div>
      <GalleryComp />
    </div>
  )
}

export default Gallery