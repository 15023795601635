import React, { useEffect, useRef, useState } from "react";
import "./gallery.css";
import play from "../../assets/images/play-btn.png";
import "swiper/element/css/pagination";
import { register } from "swiper/element/bundle";
import ReactPlayer from "react-player";
import { CloseSquare } from "iconsax-react";
import { Button } from "../custom-inputs/CustomInputs";
register();

const GallerySlide = ({ trainingText, slideData, submitBtn }) => {
  const [displayOv, setDisplayOv] = useState(false);
  const [displayItem, setDisplayItem] = useState("");

  const innerWidth = window.innerWidth;

  const swiperElRef = useRef(null);

  const showOv = (item) => {
    // console.log(item);
    setDisplayOv(!displayOv);
    setDisplayItem(item);
  };

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener("swiperprogress", (e) => {
      // const [swiper, progress] = e.detail;
      // console.log(progress);
    });

    swiperElRef.current.addEventListener("swiperslidechange", (e) => {
      // console.log("slide changed");
    });
  }, []);

  return (
    <div className="gal-slide_cont">
      <h2>{trainingText}</h2>
      <div className="gal-slide">
        <swiper-container
          ref={swiperElRef}
          slides-per-view={innerWidth > "900" ? "3" : "1.17"}
          space-between="10"
          // navigation="true"
          pagination="true"
          speed="100"
          // loop="true"
          autoplay-delay="5000"
          css-mode="true"
        >
          {slideData?.length > 0 &&
            slideData?.map((item, idx) => {
              return (
                <swiper-slide
                  key={idx}
                  className="slide-cont gal-slide"
                  lazy="true"
                >
                  {
                    <img
                      onClick={() => showOv(item)}
                      src={
                        item.mediaType !== "Video"
                          ? item?.mediaLink
                          : item?.thumbnailPic
                      }
                      alt="lazy"
                    />
                  }
                  {item.mediaType === "Video" && (
                    <div className="play-btn" onClick={() => showOv(item)}>
                      <img src={play} alt="play" />
                    </div>
                  )}
                </swiper-slide>
              );
            })}
        </swiper-container>
        <div className="g-thumb_pic">
          {displayOv && (
            <div onClick={showOv} className="overview">
              <div className="close-ov" onClick={showOv}>
                <CloseSquare />
              </div>
            </div>
          )}
          {displayOv && (
            <div className="enlarged-modal">
              {displayItem.mediaType !== "Video" && (
                <img src={displayItem?.mediaLink} alt={"caption"} />
              )}
            </div>
          )}
          {displayOv && (
            <div className="enlarged-modal">
              {displayItem.mediaType === "Video" && (
                <ReactPlayer
                  url={displayItem?.mediaLink}
                  playing={true}
                  // config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  onContextMenu={(e) => e.preventDefault()}
                  controls
                  width="100%"
                  height="500px"
                  // volume={1}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="gal-slide_btn">
        <Button btnClass={"btn"} btnText={"View All"} submitBtn={submitBtn} />
      </div>
    </div>
  );
};

export default GallerySlide;
